<template>
    <div style="position:absolute;bottom:60px;z-index: 100;">
        <v-alert
                 type="error"
                 elevation="0"
                 border="left"
                 dense
                 light
                 transition="scroll-y-transition"
         >{{message}}
        </v-alert>
    </div>
</template>
<script>
  export default {
    name: "warning",
    props:['message'],
    data:function(){
      return {

      };
    },
  }
</script>