<template>
  <div>
    <v-menu offset-y v-model="showMenu" :disabled="disabled" >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          class="actionButton"
          data-type="actionButton"
          icon
          v-bind="attrs"
          v-on="on"
          :disabled="disabled"
        >
          <slot>
            <v-icon>mdi-dots-horizontal</v-icon>
          </slot>
        </v-btn>
      </template>
      <v-list
        class="actionList"
        :id="currentTable.id + '_menuContainer_' + additional.rowindex"
        style="overflow:hidden !important;"
      >
        <dynamicComponent
          v-for="(info, actionName) in actionButtonsArray"
          :key="actionName"
          :type="info.function"
          :result="{}"
          :additional="{
            info: info,
            actionName: actionName,
            currentTable: currentTable,
            row: row,
            ...additional,
          }"
          @close-menu="closeMenu()"
          @show-warning="setupWarning"
          v-on="$listeners"
        ></dynamicComponent>
      </v-list>
    </v-menu>
    <warning class="" :message="warningMessage" v-show="showWarning"> </warning>
  </div>
</template>
<script>
import dynamicComponent from "@/commonComponents/dynamicComponent.vue";
import warning from "@/components/legacy/warning.vue";
export default {
  data() {
    return {
      showMenu: false,
      warningMessage: "Please select at least one row!",
      showWarning: false,
    };
  },
  components: { dynamicComponent, warning },
  props: {
    currentTable: {},
    row: {},
    actionButtonsArray: {},
    additional: {
      default() {
        return {};
      },
    },
    disabled: { default: false },
  },
  methods: {
    closeMenu() {
     // console.log("close");
      this.showMenu = false;
    },
    setupWarning(value) {
      console.log("setupWarning");
      this.showWarning = value;
      setTimeout(() => {
        this.showWarning = false;
      }, 3000);
    },
  },
};
</script>
<style>
.actionList .v-card:hover {
  background-color: #5c9ccc;
}
.actionList .v-card {
  font-size: 13px;
}
.actionList .v-list-item {
  padding: 0px;
  min-height: 40px;
  height: 45px;
}
.actionList .v-sheet.v-card {
  border-radius: 0px;
}

.actionList .v-list-item__title{
   height: 45px;
 }
</style>
