<template>
  <div style="max-height: 10px">
    <actionButtons
      :currentTable="currentTable"
      :actionButtonsArray="currentTable['actionButtonHeaderItem']"
    ></actionButtons>
  </div>
</template>
<script>
import actionButtons from "@/commonComponents/actionButtons.vue";
export default {
  data() {
    return {
      showMenu: false,
    };
  },
  components: { actionButtons },
  props: ["currentTable"],
  methods: {
    closeMenu() {
      console.log("close");
      this.showMenu = false;
    },
  },
};
</script>
